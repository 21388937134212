import styled, { createGlobalStyle, keyframes } from 'styled-components'
import { PageMeta } from 'components/Layout/Page'
import { EXCHANGE_DOCS_URLS } from 'config/constants'
import useMatchBreakpoints from '@pancakeswap/uikit/src/hooks/useMatchBreakpoints'
import ParticlesBackground from './Particles/Particles'
import { Flex } from '@pancakeswap/uikit'
import BackgroundImage from './Page/BackgroundImage'
import WrongNetworkCard from 'components/WrongNetwork/WrongNetwork'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { isSupportedChain } from 'config/constants/chains'
import { useEffect, useState } from 'react'

const StyledPage = styled(Flex)`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding-bottom: 0rem;
  margin-top: 48px;
  min-height: 100vh;
  overflow-y: auto;
  z-index: 25; 
  background: #000;
  @media (min-width: 1000px) {
    padding-bottom: 16px;
    min-height: 125vh;
    border-left: 3px; 
    border-left-style: outset; 
    // this messes up earn and farms pages causes major issues
    // border-top-left-radius: 32px;
    border-top: 1px solid; 
    border-color: ${({ theme }) => theme.colors.teal};
    padding: 12px;
    z-index: 25;
    max-width: calc(100vw - 155px);
    margin-left: 155px;
  }
`

// // Keyframes for animations
// const vaAnimation = keyframes`
// 0%, 100% {
//   inset: -24.9023% 50.0694% 47.8516% -5.34722%;
// }
// 25% {
//   inset: -14.0791% -11.5278% 30.4873% 52.8049%;
// }
// 50% {
//   inset: 44.6768% 11.2375% -10.8438% 41.3681%;
// }
// 75% {
//   inset: 44.1475% 41.9674% -17.9678% 5.8903%;
// }
// 100% {
//   transform: rotate(1turn);
// }
// `;
// // Keyframes for animations
// const vbAnimation = keyframes`
// 0%, 100% {
//   inset: -22.168% 6.25% 45.9961% 40.1389%;
// }
// 25% {
//   inset: 41.6094% -5.2896% -24.3408% 42.1167%;
// }
// 50% {
//   inset: 47.6484% 40.7063% -22.7559% 6.2736%;
// }
// 75% {
//   inset: -10.2031% 47.6354% 38.1748% 0.7958%;
// }
// 100% {
//   transform: rotate(1turn);
// }
// `;
// // Keyframes for animations
// const vcAnimation = keyframes`
// 0%, 100% {
//   inset: 49.2188% 45.8333% -13.2812% 11.875%;
// }
// 25% {
//   inset: -17.5791% 51.7069% 49.541% -3.5785%;

// 50% {
//   inset: -14.168% 6.284% 46.8047% 49.45%;

// 75% {
//   inset: 49.5674% 0px -7.8467% 55.4063%;

// 100% {
//   transform: rotate(1turn);
// }
// `;

// // Keyframes for animations
// const vdAnimation = keyframes`
//   0%, 100% {
//     inset: 47.2656% 11.1806% -4.10156% 55.7639%;
//   }
//   25% {
//     inset: 38.4922% 31.6243% -11.0527% 10.8972%;
//   }
//   50% {
//     inset: -15.0391% 49.6444% 46.0254% 8.4563%;
//   }
//   75% {
//     inset: 0.5752% 3.1625% 39.2627% 47.2972%;
//   }
//   100% {
//     transform: rotate(1turn);
//   }
// `;

// const GlobalStyle = createGlobalStyle`
// div:has(> .ata-bg)::after {
//   content: "";
//   position: absolute;
//   opacity: 0.5;
//   inset: 0px;
//   background: #000;
// }

//   .ata-bg {
//     opacity: 0.5;
//     filter: blur(120px);
//   }

//   .ata-bg * {
//     position: absolute;
//     background: #000;
// }


// .animation-va {
//   display: flex;
//   animation: ${vaAnimation} infinite linear 30s;
// }
// .animation-vb {
//   display: flex;
//   animation: ${vbAnimation} infinite linear 30s;
// }
// .animation-vc {
//   display: flex;
//   animation: ${vcAnimation} infinite linear 30s;
// }
// .animation-vd {
//   display: flex;
//   animation: ${vdAnimation} infinite linear 30s;
// }
// `;

// // Styled components for your classes
// const Container = styled.div`
//   flex: none;
//   inset: 0;
//   position: fixed;
//   z-index: 0;
//   overflow: hidden;
// `;

// // Styled components for your classes
// const StyledDiv = styled.div`
//   flex: none;
//   inset: 0;
//   position: fixed;
//   z-index: 0;
//   overflow: hidden;

//   @media (min-width: 1000px) {
//     margin-left: 200px;
//     margin-top: 100px;
//   }
// `;

const Page: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    removePadding?: boolean
    hideFooterOnDesktop?: boolean
    noMinHeight?: boolean
    helpUrl?: string
  }
> = ({
  children,
  removePadding = false,
  hideFooterOnDesktop = false,
  noMinHeight = false,
  helpUrl = EXCHANGE_DOCS_URLS,
  ...props
}) => {
    const { isMobile } = useMatchBreakpoints()
    const { chainId } = useActiveWeb3React()
    const [showWrongNetworkCard, setShowWrongNetworkCard] = useState(true)

    useEffect(() => {
      setShowWrongNetworkCard(!isSupportedChain(chainId))
    }, [chainId])

    const onDismiss = () => {
      setShowWrongNetworkCard(false)
    }
  
    return (
      // homeless- old
      // <>
      //   <PageMeta />
      //   <StyledPage {...props}>
      //   {/* <GlobalStyle />
      //   <Container>
      //     <StyledDiv className="ata-bg" style={{ height: '100%', width: '100%' }}>
      //       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 796 789" display="flex" className="animation-va infinite linear 20s" fill="#1D2635"><path d="M421.294 1.552c85.541 12.261 115.339 122.269 182.551 176.577 57.281 46.284 151.357 50.592 181.656 117.708 30.299 67.117-12.704 142.978-31.875 214.077-16.934 62.803-25.285 132.787-73.443 176.519-46.643 42.357-117.235 34.452-177.844 51.678-59.945 17.037-115.204 60.343-176.489 49.029-62.992-11.629-103.176-69.469-152.905-109.84C111.688 627.571-3.787 601.487.095 522.689c4.215-85.553 150.298-90.143 188.681-166.721 35.955-71.734-31.102-168.393 12.915-235.485C248.296 49.445 337.183-10.505 421.294 1.552Z"></path></svg>

      //       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 772 780" display="flex" className="animation-vb infinite linear 20s" fill="#ccc"><path d="M387.312.06c76.962-2.155 140.307 54.238 204.874 96.229 64.258 41.79 141.922 76.381 170.185 147.689 27.994 70.629-12.895 146.864-23.621 222.089-10.739 75.315 8.712 161.239-39.429 220.101-48.318 59.078-133.487 68.174-208.332 82.836-69.09 13.535-138.789 16.272-206.917-1.49-69.702-18.173-136.338-48.879-186.13-100.987C46.667 612.871-7.409 544.909.837 471.11c8.253-73.86 103.61-103.377 137.858-169.315 31.487-60.62 7.547-141.53 50.975-194.237C238.368 48.456 310.819 2.201 387.312.06Z"></path></svg>

      //       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 609 656" display="flex" className="animation-vc infinite linear 20s" fill="#F86C0D"><path d="M329.995 5.221c74.294-1.277 160.119-21.06 216.793 25.834 56.819 47.015 58.394 132.297 62.065 204.903 2.799 55.37-35.129 102.456-43.755 157.251-8.503 54.014 20.586 112.549-6.266 160.504-28.436 50.783-81.776 91.14-140.201 101.07-57.02 9.692-105.068-41.442-162.332-49.648-71.155-10.197-164.277 49.16-211.345-3.9-47.078-53.071 34.253-134.948 26.294-204.709C63.705 330.405-7.906 279.486.72 213.491 9.947 142.894 56.854 77.135 118.055 38.425 179.337-.337 256.976 6.476 329.995 5.22Z"></path></svg>

      //       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 476 582" display="flex" className="animation-vd infinite linear 20s" fill="#FEE383"><path d="M222.42 73.55c68.717-13.335 139.01-103.99 195.978-62.984 58.327 41.986 5.326 140.149 11.307 212.194 3.637 43.801 12.124 84.372 16.941 128.057 6.676 60.544 50.364 127.588 16.833 178.246-32.589 49.235-107.726 40.325-166.012 47.133-49.813 5.819-102.69 13.087-147.019-10.57-41.651-22.227-55.66-73.154-82.755-112.051C43.775 419.239 5.963 392.087.575 350.471c-5.37-41.48 28.402-76.07 38.673-116.598C52.495 181.6 29.166 113.618 69.558 78.265c39.306-34.403 101.767 5.201 152.862-4.715Z"></path></svg>

      //     </StyledDiv>
      //   </Container> */}
      //     {/* <PageBackground /> */}
      //     {/* <ParticlesBackground /> */}
      //     {children}
      //   </StyledPage>
      // </>
      // homeless- mode
      <>
      <PageMeta />
      <StyledPage {...props}>
        <BackgroundImage />
        {showWrongNetworkCard ? (
          <WrongNetworkCard onDismiss={onDismiss} />
        ) : (
          <>
            {children}
            <Flex flexGrow={1} />
          </>
        )}
      </StyledPage>
    </>
    )
  }

export default Page
