import styled from "styled-components";
import Image from 'next/image';


const StyledBackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -10;
  opacity: 0.3;
    top: -250px;

  & > span {
    height: 100% !important;
    width: 100% !important;
  }
`

const BackgroundImage = () => (
 <StyledBackgroundImage>
   <Image src="/images/arbidex/backgroundHomeNew.png" alt="Background" layout="fill" objectFit="contain" priority />
 </StyledBackgroundImage>
);

export default BackgroundImage;
