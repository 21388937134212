import { PageMeta } from 'components/Layout/Page'
import Hero from './components/Hero'
import Page from 'views/Page'

const Home: React.FC = () => {
  return (
    <>
      <PageMeta />
      <Page>
        <Hero />
      </Page>
    </>
  )
}

export default Home
