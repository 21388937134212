import { useTheme } from 'styled-components'
import { Flex, Text, Button } from '@pancakeswap/uikit'
import Trans from 'components/Trans'
import { useSwitchChain } from 'hooks/useSwitchChain'
import { DEFAULT_CHAIN_ID } from 'utils/providers'
import { UnsupportedNetworkBox, NetworkIcon } from './styled'
import { useWeb3React } from '@web3-react/core'
import { ChainId } from '@baseswapfi/sdk-core'
import GridLoader from 'react-spinners/GridLoader'
import { ChainLogo } from 'components/Logo/ChainLogo'

interface WrongNetworkProps {
  onDismiss?: () => void
}

const WrongNetworkCard: React.FC<WrongNetworkProps> = ({ onDismiss }) => {
  const theme = useTheme()
  function getChainName(chainId: ChainId): string {
    return ChainId[chainId] || 'Unknown'
  }
  const switchChain = useSwitchChain()
  const { connector } = useWeb3React()
  const handleSwitchNetwork = async (): Promise<void> => {
    try {
      await switchChain(connector, DEFAULT_CHAIN_ID)
      onDismiss()
    } catch (error) {
      console.error('Failed to switch networks:', error)
    }
  }

  return (
    <Flex marginTop="4rem">
      <UnsupportedNetworkBox>
        <Flex />
        <Flex flexDirection="column" mt="0rem" justifyContent="center" alignItems="center">
          <GridLoader
            color="#0098A1"
            size={10}
            speedMultiplier={0.3}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <Text fontSize="1.5rem" fontWeight="500" color={theme.colors.secondary} textAlign="center">
            Arbitrum Exchange is only on <span style={{ color: '#fff' }}>{getChainName(DEFAULT_CHAIN_ID)}</span>! <br />
            Please switch your connected blockchain network.
          </Text>
          <ChainLogo size={70} chainId={DEFAULT_CHAIN_ID} />
        </Flex>

        <Button onClick={handleSwitchNetwork} mb="0px">
          Switch Network
        </Button>
      </UnsupportedNetworkBox>
    </Flex>
  )
}
export default WrongNetworkCard
