import { Button, Flex, Heading, TuneIcon, Text } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useTranslation } from 'contexts/Localization'
import styled, { keyframes, css} from 'styled-components'
import { useMatchBreakpoints } from "../../../../packages/uikit/src/hooks";
import Link from 'next/link'
interface TextProps {
  isMobile?: boolean;
  isTablet?: boolean; 
  isDesktop?: boolean; 
}

const ArbitrumExchange = styled.div<TextProps>`
  color: #FFF;
  font-weight: 500;
  text-align: center;
  font-size: ${(props) => {
    if (props.isMobile) return "1.8rem";
    if (props.isTablet) return "2rem";
    if (props.isDesktop) return "2.3rem";
    return "1rem";
  }};  
  letter-spacing: ${(props) => {
    if (props.isMobile) return "1.2rem";
    if (props.isTablet) return "1.5rem";
    if (props.isDesktop) return "3rem";
    return "1rem";
  }};  
  text-transform: uppercase; 
  margin-bottom: ${(props) => (props.isMobile ? '5rem' : '2rem')};
  margin-top: ${(props) => (props.isMobile ? '3rem' : '1.5rem')};
  background: -webkit-linear-gradient(#fff, #8797a9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;


const PeoplesDex = styled.div<TextProps>`
font-size: ${(props) => {
  if (props.isMobile) return "1.5rem";
  if (props.isTablet) return "1.5rem";
  if (props.isDesktop) return "1.8rem";
  return "1rem";
}};
  font-weight: 700; 
  text-align: center; 
  margin-bottom: ${(props) => (props.isMobile ? '1.1rem' : '1rem')};
  line-height: ${(props) => (props.isMobile ? '2rem' : '3rem')};
  letter-spacing: ${(props) => (props.isMobile ? '8px' : '8px')};
  text-transform: uppercase; 
  background: linear-gradient(360deg, #7303c0, #F86C0D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
 
`;

const Unbound = styled.div<TextProps>`
  color: #FFF;
  font-size: ${(props) => {
    if (props.isMobile) return "1rem";
    if (props.isTablet) return "1rem";
    if (props.isDesktop) return "1.5rem";
    return "1rem";
  }};
  font-weight: 500;
  line-height:  ${(props) => (props.isMobile ? '2.1rem' : '2.1rem')};

  text-align: center;
  margin-bottom: ${(props) => (props.isMobile ? '1.3rem' : '0rem')};
  text-transform: uppercase; 

`;
const Embrace = styled.div<TextProps>`
  color: #FFF;
  font-size: ${(props) => {
    if (props.isMobile) return "1rem";
    if (props.isTablet) return "1rem";
    if (props.isDesktop) return "1.5rem";
    return "1rem";
  }};
  font-weight: 500;
  text-align: center;
  line-height:  ${(props) => (props.isMobile ? '2.1rem' : '2.1rem')};
  margin-bottom: ${(props) => (props.isMobile ? '1.3rem' : '0rem')};
  letter-spacing: ${(props) => (props.isMobile ? '8px' : '18px')};
  text-transform: uppercase; 

  background: -webkit-linear-gradient(270deg, #fff, #ccc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;


const MobileSpan = styled.span`
color: #fff; 
font-size: 1.1rem; 
 
`;

const Hero = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { isMobile, isTablet, isDesktop } = useMatchBreakpoints();
  return (
<>
  <Flex
    position="relative"
    flexDirection={['column', null, null, 'column']}
    alignItems={['flex-start', null, null, 'center']}
    justifyContent={['flex-start', null, null, 'center']}
    mt={[account ? '5px' : '5px', null, '3rem']}
    id="homepage-hero" 
  >
    <Flex 
    flexDirection="column"
    width="100%" justifyContent="center"
    alignItems="center"  >
       <Flex flexDirection="row"  justifyContent="center">
      <ArbitrumExchange isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>
            ArbiDe<span style={{ letterSpacing: '0rem' }}>x</span>
      </ArbitrumExchange>
      </Flex>
      <Flex flexDirection="row" >
        <PeoplesDex isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>
                The People's DEX
        </PeoplesDex>
      </Flex>

      <Flex flexDirection="row" >
        <Unbound isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>
                Introducing v3 liquidity
        </Unbound>
      </Flex>

    </Flex>


    <Flex 
    flexDirection={[ 'column', null, null, 'row' ]}
    marginLeft={['0rem', null, null, '0rem']} 
    marginTop={['3rem', null, null, '4rem' ]} 
    alignItems={['center', null, null, 'flex-end']}
    justifyContent={['center', null, null, 'center' ]}>
      {!account && 
      <ConnectWalletButton 
      variant="four" 
      paddingY={['1.5rem', null, null, null]}
      width={[ '90vw', null, null, '25vw' ]}
      className="glow2"
      mx={[ '0px', null, null, '12px' ]}>
        <MobileSpan>
        {t('CONNECT WALLET')} 
        </MobileSpan>  
      </ConnectWalletButton>
      }
      <NextLinkFromReactRouter to="/swap">
        <Button 
        paddingY={['1.5rem', null, null, null]}
        mt={[ '16px', null, null, null ]}
        mx={[ '0px', null, null, '12px' ]}
        width={[ '90vw', null, null, '25vw' ]}
        variant="four" className="connectglow">
          <MobileSpan>
              {t('SWAP NOW')} 
          </MobileSpan>
        </Button>
      </NextLinkFromReactRouter>
    </Flex>
    <Flex 
    flexDirection="column"
    width={['100%', null, null, '70%' ]} 
    alignItems="center" 
    mt={100}
    >
        <Embrace isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>
          Embrace the Ape
        </Embrace>
    </Flex>

  </Flex>
</>
  )
}

export default Hero
