import styled, { css, useTheme } from 'styled-components'
import { Flex } from '@pancakeswap/uikit'
import { AlertTriangle, Inbox } from 'react-feather'

export const UnsupportedNetworkBox = styled(Flex)`
  min-width: 250px;
  flex-direction: column;
  height: 40vh;
  padding: 1rem;
  padding-top: 1.5rem;
  justify-content: space-between;
  border-radius: 18px;
  box-shadow: 0 0 12px #000;
  flex-direction: column;
  background: none;
  background-color: none;
  backdrop-filter: blur(1px);
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.textDisabled};
  align-items: center;
  margin-top: 4rem;
`
export const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`

export const NetworkIcon = styled(AlertTriangle)`
  ${IconStyle}
`

export const InboxIcon = styled(Inbox)`
  ${IconStyle}
`
